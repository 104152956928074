import Vue from 'vue'

const globalComponents = {
  AppLink: () => import('../../components/common/AppLink.vue' /* webpackChunkName: "components/common/AppLink" */).then(c => c.default || c),
  Banner: () => import('../../components/common/Banner.vue' /* webpackChunkName: "components/common/Banner" */).then(c => c.default || c),
  BreakfastStep: () => import('../../components/common/BreakfastStep.vue' /* webpackChunkName: "components/common/BreakfastStep" */).then(c => c.default || c),
  Callout: () => import('../../components/common/Callout.vue' /* webpackChunkName: "components/common/Callout" */).then(c => c.default || c),
  CalloutCards: () => import('../../components/common/CalloutCards.vue' /* webpackChunkName: "components/common/CalloutCards" */).then(c => c.default || c),
  Card: () => import('../../components/common/Card.vue' /* webpackChunkName: "components/common/Card" */).then(c => c.default || c),
  CarouselCard: () => import('../../components/common/CarouselCard.vue' /* webpackChunkName: "components/common/CarouselCard" */).then(c => c.default || c),
  CateringLocations: () => import('../../components/common/CateringLocations.vue' /* webpackChunkName: "components/common/CateringLocations" */).then(c => c.default || c),
  DefaultButton: () => import('../../components/common/DefaultButton.vue' /* webpackChunkName: "components/common/DefaultButton" */).then(c => c.default || c),
  DefaultSection: () => import('../../components/common/DefaultSection.vue' /* webpackChunkName: "components/common/DefaultSection" */).then(c => c.default || c),
  FaveLocationModal: () => import('../../components/common/FaveLocationModal.vue' /* webpackChunkName: "components/common/FaveLocationModal" */).then(c => c.default || c),
  Featured: () => import('../../components/common/Featured.vue' /* webpackChunkName: "components/common/Featured" */).then(c => c.default || c),
  GoogleStaticMap: () => import('../../components/common/GoogleStaticMap.vue' /* webpackChunkName: "components/common/GoogleStaticMap" */).then(c => c.default || c),
  Hero: () => import('../../components/common/Hero.vue' /* webpackChunkName: "components/common/Hero" */).then(c => c.default || c),
  Iframe: () => import('../../components/common/Iframe.vue' /* webpackChunkName: "components/common/Iframe" */).then(c => c.default || c),
  LocationModal: () => import('../../components/common/LocationModal.vue' /* webpackChunkName: "components/common/LocationModal" */).then(c => c.default || c),
  Locations: () => import('../../components/common/Locations.vue' /* webpackChunkName: "components/common/Locations" */).then(c => c.default || c),
  LocationsCorporate: () => import('../../components/common/LocationsCorporate.vue' /* webpackChunkName: "components/common/LocationsCorporate" */).then(c => c.default || c),
  LoyaltyOptInModal: () => import('../../components/common/LoyaltyOptInModal.vue' /* webpackChunkName: "components/common/LoyaltyOptInModal" */).then(c => c.default || c),
  PageText: () => import('../../components/common/PageText.vue' /* webpackChunkName: "components/common/PageText" */).then(c => c.default || c),
  Perks: () => import('../../components/common/Perks.vue' /* webpackChunkName: "components/common/Perks" */).then(c => c.default || c),
  PerksHero: () => import('../../components/common/PerksHero.vue' /* webpackChunkName: "components/common/PerksHero" */).then(c => c.default || c),
  ProductCard: () => import('../../components/common/ProductCard.vue' /* webpackChunkName: "components/common/ProductCard" */).then(c => c.default || c),
  Promotions: () => import('../../components/common/Promotions.vue' /* webpackChunkName: "components/common/Promotions" */).then(c => c.default || c),
  Review: () => import('../../components/common/Review.vue' /* webpackChunkName: "components/common/Review" */).then(c => c.default || c),
  RichText: () => import('../../components/common/RichText.vue' /* webpackChunkName: "components/common/RichText" */).then(c => c.default || c),
  SbRichText: () => import('../../components/common/SbRichText.vue' /* webpackChunkName: "components/common/SbRichText" */).then(c => c.default || c),
  Section: () => import('../../components/common/Section.vue' /* webpackChunkName: "components/common/Section" */).then(c => c.default || c),
  StaticLocationMap: () => import('../../components/common/StaticLocationMap.vue' /* webpackChunkName: "components/common/StaticLocationMap" */).then(c => c.default || c),
  StorySlider: () => import('../../components/common/StorySlider.vue' /* webpackChunkName: "components/common/StorySlider" */).then(c => c.default || c),
  Storyline: () => import('../../components/common/Storyline.vue' /* webpackChunkName: "components/common/Storyline" */).then(c => c.default || c),
  Subscribe: () => import('../../components/common/Subscribe.vue' /* webpackChunkName: "components/common/Subscribe" */).then(c => c.default || c),
  ThreeSteps: () => import('../../components/common/ThreeSteps.vue' /* webpackChunkName: "components/common/ThreeSteps" */).then(c => c.default || c),
  LocationHero: () => import('../../components/locations/LocationHero.vue' /* webpackChunkName: "components/locations/LocationHero" */).then(c => c.default || c),
  LocationItem: () => import('../../components/locations/LocationItem.vue' /* webpackChunkName: "components/locations/LocationItem" */).then(c => c.default || c),
  AccountHero: () => import('../../components/account/AccountHero.vue' /* webpackChunkName: "components/account/AccountHero" */).then(c => c.default || c),
  AccountSignupForm: () => import('../../components/account/AccountSignupForm.vue' /* webpackChunkName: "components/account/AccountSignupForm" */).then(c => c.default || c),
  ForgotPassword: () => import('../../components/account/ForgotPassword.vue' /* webpackChunkName: "components/account/ForgotPassword" */).then(c => c.default || c),
  LoyaltySignupForm: () => import('../../components/loyalty/LoyaltySignupForm.vue' /* webpackChunkName: "components/loyalty/LoyaltySignupForm" */).then(c => c.default || c),
  CcpaForm: () => import('../../components/form/CCPAForm.vue' /* webpackChunkName: "components/form/CCPAForm" */).then(c => c.default || c),
  DynamicForm: () => import('../../components/form/DynamicForm.vue' /* webpackChunkName: "components/form/DynamicForm" */).then(c => c.default || c),
  EclubForm: () => import('../../components/form/EclubForm.vue' /* webpackChunkName: "components/form/EclubForm" */).then(c => c.default || c),
  FormButton: () => import('../../components/form/FormButton.vue' /* webpackChunkName: "components/form/FormButton" */).then(c => c.default || c),
  FormField: () => import('../../components/form/FormField.vue' /* webpackChunkName: "components/form/FormField" */).then(c => c.default || c),
  FormstackForm: () => import('../../components/form/FormstackForm.vue' /* webpackChunkName: "components/form/FormstackForm" */).then(c => c.default || c),
  PageForm: () => import('../../components/form/PageForm.vue' /* webpackChunkName: "components/form/PageForm" */).then(c => c.default || c),
  PhoneField: () => import('../../components/form/PhoneField.vue' /* webpackChunkName: "components/form/PhoneField" */).then(c => c.default || c),
  ZipField: () => import('../../components/form/ZipField.vue' /* webpackChunkName: "components/form/ZipField" */).then(c => c.default || c),
  Accordian: () => import('../../components/franchising/Accordian.vue' /* webpackChunkName: "components/franchising/Accordian" */).then(c => c.default || c),
  ArticleCategory: () => import('../../components/franchising/ArticleCategory.vue' /* webpackChunkName: "components/franchising/ArticleCategory" */).then(c => c.default || c),
  ByTheNumbers: () => import('../../components/franchising/ByTheNumbers.vue' /* webpackChunkName: "components/franchising/ByTheNumbers" */).then(c => c.default || c),
  Disclaimer: () => import('../../components/franchising/Disclaimer.vue' /* webpackChunkName: "components/franchising/Disclaimer" */).then(c => c.default || c),
  FranchiseNav: () => import('../../components/franchising/FranchiseNav.vue' /* webpackChunkName: "components/franchising/FranchiseNav" */).then(c => c.default || c),
  FranchisingBlogSidebar: () => import('../../components/franchising/FranchisingBlogSidebar.vue' /* webpackChunkName: "components/franchising/FranchisingBlogSidebar" */).then(c => c.default || c),
  FranchisingFooter: () => import('../../components/franchising/FranchisingFooter.vue' /* webpackChunkName: "components/franchising/FranchisingFooter" */).then(c => c.default || c),
  FranchisingFormSidebar: () => import('../../components/franchising/FranchisingFormSidebar.vue' /* webpackChunkName: "components/franchising/FranchisingFormSidebar" */).then(c => c.default || c),
  FranchisingHero: () => import('../../components/franchising/FranchisingHero.vue' /* webpackChunkName: "components/franchising/FranchisingHero" */).then(c => c.default || c),
  Grow: () => import('../../components/franchising/Grow.vue' /* webpackChunkName: "components/franchising/Grow" */).then(c => c.default || c),
  HeroText: () => import('../../components/franchising/HeroText.vue' /* webpackChunkName: "components/franchising/HeroText" */).then(c => c.default || c),
  IconSection: () => import('../../components/franchising/IconSection.vue' /* webpackChunkName: "components/franchising/IconSection" */).then(c => c.default || c),
  InTheNews: () => import('../../components/franchising/InTheNews.vue' /* webpackChunkName: "components/franchising/InTheNews" */).then(c => c.default || c),
  InvestSuccess: () => import('../../components/franchising/InvestSuccess.vue' /* webpackChunkName: "components/franchising/InvestSuccess" */).then(c => c.default || c),
  LearnMore: () => import('../../components/franchising/LearnMore.vue' /* webpackChunkName: "components/franchising/LearnMore" */).then(c => c.default || c),
  MeetTheTeam: () => import('../../components/franchising/MeetTheTeam.vue' /* webpackChunkName: "components/franchising/MeetTheTeam" */).then(c => c.default || c),
  MobileStickyForm: () => import('../../components/franchising/MobileStickyForm.vue' /* webpackChunkName: "components/franchising/MobileStickyForm" */).then(c => c.default || c),
  Ownership: () => import('../../components/franchising/Ownership.vue' /* webpackChunkName: "components/franchising/Ownership" */).then(c => c.default || c),
  Prototypes: () => import('../../components/franchising/Prototypes.vue' /* webpackChunkName: "components/franchising/Prototypes" */).then(c => c.default || c),
  StepNav: () => import('../../components/franchising/StepNav.vue' /* webpackChunkName: "components/franchising/StepNav" */).then(c => c.default || c),
  StickyContact: () => import('../../components/franchising/StickyContact.vue' /* webpackChunkName: "components/franchising/StickyContact" */).then(c => c.default || c),
  Territories: () => import('../../components/franchising/Territories.vue' /* webpackChunkName: "components/franchising/Territories" */).then(c => c.default || c),
  Testimonials: () => import('../../components/franchising/Testimonials.vue' /* webpackChunkName: "components/franchising/Testimonials" */).then(c => c.default || c),
  Timeline: () => import('../../components/franchising/Timeline.vue' /* webpackChunkName: "components/franchising/Timeline" */).then(c => c.default || c),
  WhyPerkinsVideo: () => import('../../components/franchising/WhyPerkinsVideo.vue' /* webpackChunkName: "components/franchising/WhyPerkinsVideo" */).then(c => c.default || c),
  GenericMenuHero: () => import('../../components/menu/GenericMenuHero.vue' /* webpackChunkName: "components/menu/GenericMenuHero" */).then(c => c.default || c),
  MenuCarousel: () => import('../../components/menu/MenuCarousel.vue' /* webpackChunkName: "components/menu/MenuCarousel" */).then(c => c.default || c),
  MenuHero: () => import('../../components/menu/MenuHero.vue' /* webpackChunkName: "components/menu/MenuHero" */).then(c => c.default || c),
  MenuSlider: () => import('../../components/menu/MenuSlider.vue' /* webpackChunkName: "components/menu/MenuSlider" */).then(c => c.default || c),
  DisplayErrors: () => import('../../components/global/DisplayErrors.vue' /* webpackChunkName: "components/global/DisplayErrors" */).then(c => c.default || c),
  Footer: () => import('../../components/global/Footer.vue' /* webpackChunkName: "components/global/Footer" */).then(c => c.default || c),
  Header: () => import('../../components/global/Header.vue' /* webpackChunkName: "components/global/Header" */).then(c => c.default || c),
  Loader: () => import('../../components/global/Loader.vue' /* webpackChunkName: "components/global/Loader" */).then(c => c.default || c),
  LocationSelector: () => import('../../components/global/LocationSelector.vue' /* webpackChunkName: "components/global/LocationSelector" */).then(c => c.default || c),
  LocationWidget: () => import('../../components/global/LocationWidget.vue' /* webpackChunkName: "components/global/LocationWidget" */).then(c => c.default || c),
  Logo: () => import('../../components/global/Logo.vue' /* webpackChunkName: "components/global/Logo" */).then(c => c.default || c),
  MobileMenu: () => import('../../components/global/MobileMenu.vue' /* webpackChunkName: "components/global/MobileMenu" */).then(c => c.default || c),
  SiteHeader: () => import('../../components/global/SiteHeader.vue' /* webpackChunkName: "components/global/SiteHeader" */).then(c => c.default || c),
  WhiteLoader: () => import('../../components/global/WhiteLoader.vue' /* webpackChunkName: "components/global/WhiteLoader" */).then(c => c.default || c)
}

for (const name in globalComponents) {
  Vue.component(name, globalComponents[name])
}
