//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    computed: {
        user() {
            return this.$store.state.activeUser
        },
        cart() {
            return this.$store.state.activeOrder
        },
        menu() {
            return this.$store.state.activeMenu
        },
        cats() {
            let cats = []
            for (let sup = 0; sup < this.menu.supercategories.length; sup++) {
                this.menu.supercategories[sup].subcategories.map(s => {
                    this.menu.categories.map(c => {
                        if (s.id.indexOf(c.id) > -1) {
                            cats.push(c)
                        }
                    })
                })
            }
            return cats
        },
        loc() {
            return this.$store.state.location
        },
        handoffText() {
            switch (this.handoff) {
                case 'pickup':
                    return 'Pickup'
                    break
                case 'curbside':
                    return 'Curbside'
                    break
                default:
                    return 'Delivery'
            }
        }
    },
    data() {
        let quantities = []
        let q = 0
        while (q < 100) {
            quantities.push(q)
            q++
        }
        return {
            itemToRemove: '',
            quantities: quantities,
            handoff: 'pickup',
            offers: null
        }
    },
    mounted() {
        this.getOrderOffers()
        if (this.cart) {
            this.handoff = this.cart.handoff
        }
    },
    methods: {
        isSingle(prod) {
            let single = false
            if (this.menu.singles) {
                this.menu.singles.map(c => {
                    c.products.map(p => {
                        if (prod.name === p.name) {
                            single = true
                        }
                    })
                })
            }
            return single
        },
        editItem(item) {

            let id = null
            let sku = null
            let link = {}
            console.log(this.cats)
            for (let c = 0; c < this.cats.length; c++) {
                for (let p = 0; p < this.cats[c].products.length; p++) {
                    if (this.cats[c].products[p].id === item.product_id) {
                        id = this.cats[c].id
                        sku = this.cats[c].products[p].sku
                    }
                }
            }

            // link = {
            //   path: '../order/product',
            //   query: {
            //     cat: id,
            //     id: sku,
            //     cid: item.product_id
            //   }
            // }

            let path = this.$route.fullPath

            if (!path.includes('product')) {
                this.$router.push(`../order/product/?cat=${id}&id=${sku}&cid=${item.product_id}`)
                this.$store.commit('setGlobalCart', false)
            } else {
                this.$router.push(`../../order/product/?cat=${id}&id=${sku}&cid=${item.product_id}`)
                this.$store.commit('setLoading', true)
                setTimeout(() => {
                    this.$router.go()
                }, 100)
            }

            // setTimeout(() => {
            //   this.$router.go(link)
            // }, 400)
        },
        closeCart() {
            this.$store.commit('setGlobalCart', false)
        },
        startOrder() {
            this.$router.push('/order')
            this.closeCart()
        },
        async getOrderOffers() {
            this.offers = await this.$api.getOffersForOrder()
            console.log('Order Offers: ', this.offers)
        },
        async setHandoff(mode) {
            let order = null
            switch (mode) {
                case 'delivery':
                case 'dispatch':
                    this.handoff = mode
                    order = JSON.parse(JSON.stringify(this.cart))
                    order.handoff = mode
                    this.$store.commit('setActiveOrder', order)
                    break
                default:
                    this.$store.commit('setLoading', true)
                    order = await this.$api.setHandoff(this.cart.id, mode)
                    this.$store.commit('setLoading', false)
                    if (order) {
                        this.handoff = mode
                        this.$store.commit('setActiveOrder', order)
                    }
            }
        },
        async handleQuantityChange(e, p) {
            console.log(e.target.value == 0)
            this.$store.commit('setLoading', true)
            let order = null
            if (e.target.value == 0) {
                order = await this.$api.removeFromOrder(this.cart.id, p.id)
            } else {
                let product = {
                    id: p.product_id,
                    quantity: e.target.value,
                    choices: []
                }
                p.choices.map(c => {
                    product.choices.push(c.option_id)
                })
                product.choices = product.choices.join(',')
                order = await this.$api.updateInOrder(this.cart.id, p.id, product)
            }
            this.$store.commit('setLoading', false)
            if (order) {
                this.$store.commit('setActiveOrder', order)
            }
        },
        addToDataLayersGA(products, productsId, location, eventType) {
            dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            const index = products.findIndex(object => {
                return object.id == productsId
            })
            if (index > -1) {
                console.log('products[index]::: ', products[index])
                dataLayer.push({
                    event: eventType,
                    ecommerce: {
                        currency: "USD",
                        value: products[index].cost.total ? products[index].cost.total : products[index].cost,
                        items: [{
                            item_id: products[index].id,
                            item_name: products[index].name,
                            location_id: location.id,
                            price: products[index].cost.total ? products[index].cost.total : products[index].cost,
                            quantity: products[index].quantity
                        }]
                    }
                });
            }
        },
        async confirmRemoval(id) {
            this.$store.commit('setLoading', true)
            let order = await this.$api.removeFromOrder(this.cart.id, id)
            this.addToDataLayersGA(this.cart.products, id, this.$store.state.location, "remove_from_cart")
            this.$store.commit('setLoading', false)
            if (order) {
                if (order.products.length > 0) {
                    this.$store.commit('setLoading', true)
                    let validate = await this.$api.validateOrder(this.cart.id)
                    this.$store.commit('setLoading', false)
                    if (validate) {
                        order.taxes = validate.taxes
                        order.totals = validate.totals
                        this.$store.commit('setActiveOrder', order)
                    }
                } else {
                    this.$store.commit('setActiveOrder', order)
                    this.$router.push('/order')
                }
            }
        },

        checkout() {
            this.$store.commit('setGlobalCart', false)
            if (this.user) {
                this.$router.push('/order/checkout')
            } else {
                this.$store.commit('setRedirect', '/order/checkout')
                this.$router.push('/order/signin')
            }
        }
    }
}
