export const actions = {
    //// thses functions can be mapped out with the Dynamic Form Component
    // async postTwinsRegistation({ commit }, payload) {  
    //     console.log(payload); 
    //      try {
    //        return  await this.$submit.send(payload.formTitle, payload.fields, payload.values)
    //      } catch (error) {
    //          console.log(error);
    //          return error
    //      }
    // }  
}