//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { TheMask } from 'vue-the-mask'

 export default {
  data() {
    return {
      optIn: false,
      helper: false,
      charHelper: false,
      upperHelper: false,
      numHelper: false,
      // specHelper: false,
    }
  },
  components: {
    TheMask
  }, 
   props: {
    index:{
     type: Number,
      default: 0
    },
    blok: {
      type: Object,
      default: () => {}
    },
    model: {
      type: Object,
      default: () => {}
    },
    onBlur: {
      type: Function,
      default: () => {}
    },
    locations: {
      type: Array,
      default: []
    },
    searched: {
      type: Boolean,
      default: false
    },
    errors:{
     type: Array,
      default: () =>  []
    },
  },
  mounted() {
    console.log(this.formType)
  },
  methods: {
    testPsw(psw) {
      //Verify password length
      if (psw.length > 5) {
        this.charHelper = true
      } else {
        this.charHelper = false
      }

      // Verify password contains uppercase
      this.upperHelper = /[A-Z]/.test(psw)

      // Verify password contains number
      this.numHelper = /\d/.test(psw)

      // Verify password contains special character
      // this.specHelper = /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(psw)
    },
    async setLoyalty(e) {
      let location = await this.$api.getLocation(e.target.value)
      console.log('Location: ', location, e.target.value)

      if (location.loyalty === true) {
        this.$store.commit('setFaveLocationLoyalty', true)
      } else {
        this.$store.commit('setFaveLocationLoyalty', false)
      }
    },
    setOptIn() {
      if (this.$store.state.loyaltySignupOptIn === false){
        this.$store.commit('setLoyaltySignupOptIn', true)
      } else {
        this.$store.commit('setLoyaltySignupOptIn', false)
      }
    }
  },
  computed: {
    error() {
        return this.errors ? this.errors.find( (e)=> e.id === this.blok.id ) :  ''
    },

    errorMessage() {
        return this.error? this.error.message : ''
    },
    formStyle(){
      const err = this.error   ? 'border-red': ''
        return `w-full block border p-4 lg:text-lg ${err}`
    },
    formType() {
      return this.blok.type
    },
  },
}
