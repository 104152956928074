// formulate.config.js
import { en } from '@braid/vue-formulate-i18n'
import zip from '~/plugins/custom_inputs/zip'
import phone from '~/plugins/custom_inputs/phone'

export default {
  plugins: [en, zip, phone],
  locale: 'en',
  useInputDecorators: false,
  classes: {
    outer: 'mb-4',
    input(context) {
      switch (context.classification) {
 
        case 'box':
          return 'rounded-full '
        case 'checkbox':
          return 'rounded-full ' 
        case 'zip':
          return 'border border-gray-400 rounded px-3 py-2 leading-none focus:border-green-500 outline-none border-box w-full mb-1'
        case 'phone':
          return 'border border-gray-400 rounded px-3 py-2 leading-none focus:border-green-500 outline-none border-box w-full mb-1'
        case 'button':
          return 'w-full border-2 border-green bg-green text-white hover:bg-white hover:text-green rounded-full text-lg py-3 font-bold mb-2'
        default:
          return 'w-full block border p-4 lg:text-lg '
      }
    },
    label: 'font-bold text-xs',
    help: 'text-xs my-1 text-red-300',
    error: 'text-red-300 text-xs my-1'
  },
  rules: {
    foobar: ({ value }) => ['foo', 'bar'].includes(value)
  }
}
