//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: [
    'content'
  ],
  computed: {
    orderType() {
      return this.$store.state.orderType
    },
    heading() {
      return 'Find your favorite location!'
    },
  },
  data() {
    return {
      showModal: true,
      results: true,
      locations: [],
      searching: false,
      searchTerm: null,
      city: null,
      zipcode: null
    }
  },
  methods: {
    findme() {
      if(navigator.geolocation) {
        this.searching = true
        navigator.geolocation.getCurrentPosition(this.getCoordinates, this.getCoordinatesError)
      } else {
        this.$store.commit('setActiveErrors', ['Please enable location to use this feature.'])
      }
    },
    async getCoordinates(position) {
      let coords = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }
      let search = await this.$api.findLocations(coords)
      console.log('Restuls: ', search)
      this.locations = search.locations
      this.searching = false
    },
    getCoordinatesError() {
      this.searching = false
      this.$store.commit('setActiveErrors', ['Please enable location to use this feature.'])
    },
    async search() {
      if(this.searchTerm) {
        let searchTerm = this.searchTerm
        if(this.orderType == 'delivery') {
          searchTerm += `, ${this.city}, ${this.zipcode}`
        }
        this.searching = true
        let search = await this.$api.findLocations({ address: searchTerm })
        let locations = []
        search.locations.map(l => {
          let day = new Date().getDay()
          if(l.hours[day].closed == false) {
            locations.push(l)
          }
        })
        this.locations = locations
        this.searching = false
      }
    },
    async startOrder(l) {
      console.log('modal test: ', l)
      this.$store.commit('setLoading', true)
      this.$store.commit('setFaveLocationLoyalty', l.loyalty ? l.loyalty : false)
      let oldFaves = await this.$api.setFaveLocation(l.menu_id)
      for (let x = 0; x < oldFaves.favelocations.length; x++) {
        await this.$api.deleteFaveLocation(oldFaves.favelocations[x].menu_id)
      }
      let newFave = await this.$api.setFaveLocation(l.menu_id)
      this.$store.commit('setLoading', false)
      console.log(newFave.favelocations)
      if(newFave) {
        let user = JSON.parse(JSON.stringify(this.$store.state.activeUser))
        if(user.favelocations) {
          user.favelocations = [newFave.favelocations[0]]
        } else {
          user.favelocations.push(newFave.favelocations[0])
        }
        this.$store.commit('setActiveUser', user)
        this.close()
      }
    },
    close() {
      // Prevent and redirect if on page that requires location
      this.showModal = false
      setTimeout(this.remove, 1000)
      
    },
    remove() {
      this.$store.commit('setFaveLocationSelector', false)
    }
  }
}
