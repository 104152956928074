//
//
//
//
//
//
//
//
//

export default {
  head() {
    return {
      title: 'Page Not Found'
    }
  }
}
