import { render, staticRenderFns } from "./franchising.vue?vue&type=template&id=74d85d7d&"
import script from "./franchising.vue?vue&type=script&lang=js&"
export * from "./franchising.vue?vue&type=script&lang=js&"
import style0 from "./franchising.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Footer: require('/opt/build/repo/components/global/Footer.vue').default,GlobalCart: require('/opt/build/repo/components/order/cart/GlobalCart.vue').default,LocationModal: require('/opt/build/repo/components/common/LocationModal.vue').default,FaveLocationModal: require('/opt/build/repo/components/common/FaveLocationModal.vue').default,Loader: require('/opt/build/repo/components/global/Loader.vue').default,DisplayErrors: require('/opt/build/repo/components/global/DisplayErrors.vue').default,MobileStickyForm: require('/opt/build/repo/components/franchising/MobileStickyForm.vue').default})
