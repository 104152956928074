//
//
//
//
//
//
//
//
//
//
//
//

export default {
  computed: {
    showLoader() {
      return this.$store.state.loading
    },
    screen() {
      return 'fixed inset-0 bg-white-80 flex items-center justify-center z-20'
    },
    errors() {
      return this.$store.state.activeErrors
    },
  }
}
