export default (context, inject) => {
  const api = {
    async request(obj, verify = false, supress = false) {
      obj.url = `${window.location.origin}/api`
      if(process.env.REQUIRE_VERIFY == 'true' && verify) {
        obj.headers.verify = await context.$recaptcha.execute('login')
      }
      obj.headers.env = context.store.state.env
      obj.headers.client_type = context.store.state.clientType
      return context.$axios(obj)
        .then(res => {
          return res.data
        })
        .catch(err => {
            if (obj.upsells ) {
                console.log('an upsell error')
                return {
                    action: 'redirect',
                    prodId: obj.data.items[0].id
                }
            }
          if(err.response && err.response.data && err.response.data.message && supress == false) {
            context.store.commit('setActiveErrors', [err.response.data.message])
            if(err.response.data.message == 'This order has already been placed') {
              let orderid = obj.headers.path.replace(/orders\/(.*?)\/submit/, '$1')
              context.store.commit('setRedirect', `/order/confirm?id=${orderid}`)
              context.store.commit('setActiveOrder', null)
            } else if(err.response.data.message == 'Could not load shopping basket') {
              context.store.commit('setActiveOrder', null)
            }
          } else if(err.response && Array.isArray(err.response.data)) {
            let errors = []
            err.response.data.map(e => {
              errors.push(e.message)
            })
            context.store.commit('setActiveErrors', errors)
          }
          //  else {
          //   context.store.commit('setActiveErrors', ['Connection issue. Please try again'])
          // }
          return null
        })
    },
    async memberSignup(d) {
      if(context.store.state.activeOrder) {
        d.order_id = context.store.state.activeOrder.id
      }
      return this.request({
        method: 'post',
        headers: {
          path: 'members/create'
        },
        data: d
      }, true)
    },
    async memberSignin(u, p) {
      let data = {
        username: u,
        password: p
      }
      if(context.store.state.activeOrder) {
        data.order_id = context.store.state.activeOrder.id
      }
      return this.request({
        method: 'post',
        headers: {
          path: 'members/signin'
        },
        data: data
      }, true)
    },
    async forgotPassword(e) {
      let data = {
        email: e
      }
      return this.request({
        method: 'post',
        headers: {
          path: 'members/forgotPassword'
        },
        data: data
      }, true)
    },
    // async verifyPasswordLink(c) {
    //   return this.request({
    //     method: 'get',
    //     headers: {
    //       path: 'members/verifyForgotPasswordLink',
    //       token: c
    //     }
    //   }, true)
    // },
    async resetPassword(id, psw = null) {
      if (psw) {
        let data = {
          token: id,
          password: psw
        }
        return this.request({
          method: 'post',
          headers: {
            path: 'members/resetPassword'
          },
          data: data
        }, true)
      } else {
        return this.request({
          method: 'get',
          headers: {
            path: 'members/verifyForgotPasswordLink',
            token: id
          }
        }, true)
      }
    },
    async memberCodeExchange(c) {
      return this.request({
        method: 'post',
        headers: {
          path: 'members/code'
        },
        data: {
          code: c
        }
      }, false, true)
    },
    async getMemberProfile() {
      let headers = {
        path: 'members/get',
        token: context.store.state.userToken.access.token
      }
      if(context.store.state.userToken.ordering.access) {
        headers.order_token = context.store.state.userToken.ordering.access.token
      }
      return this.request({
        method: 'get',
        headers: headers
      }, false, true)
    },
    async updateMemberProfile(d) {
      return this.request({
        method: 'put',
        headers: {
          path: 'members/update',
          token: context.store.state.userToken.access.token
        },
        data: d
      })
    },
    async findLocations(loc) {
      return this.request({
        method: 'get',
        headers: {
          path: 'locations/search'
        },
        params: loc
      })
    },
    async findMemberLocations(loc) {
      return this.request({
        method: 'get',
        headers: {
          path: 'members/locations'
        },
        params: loc
      })
    },
    async getLoaction(id) {
      return this.request({
        method: 'get',
        headers: {
          path: `locations/${id}`
        }
      })
    },
    async setFaveLocation(id) {
      return this.request({
        method: 'post',
        headers: {
          path: `members/faves/locations/${id}`,
          token: context.store.state.userToken.ordering.access.token
        }
      })
    },
    async deleteFaveLocation(id) {
      return this.request({
        method: 'delete',
        headers: {
          path: `members/faves/locations/${id}`,
          token: context.store.state.userToken.ordering.access.token
        }
      })
    },
    async getLocation(id, withMenu = false) {
      let request = {
        method: 'get',
        headers: {
          path: `locations/${id}`
        }
      }

      if(withMenu) {
        request.params = {
          withMenu: true
        }
      }

      let loc = await this.request(request)
      
      if(loc.menu) {
        loc.menu.supercategories = context.store.state.supercategories
      }
      return loc
    },
    async getMenu(mid) {
      let menu = await this.request({
        method: 'get',
        headers: {
          path: `menus/${mid}`
        }
      })
      if(menu) {
        menu.supercategories = context.store.state.supercategories
      }
      return menu
    },
    async getProduct(mid, pid) {
      return this.request({
        method: 'get',
        headers: {
          path: `menus/${mid}/products/${pid}`
        }
      })
    },
    async getOrders() {
      return this.request({
        method: 'get',
        headers: {
          path: 'orders/recent',
          token: context.store.state.userToken.ordering.access.token
        }
      })
    },
    async reorder(id) {
      return this.request({
        method: 'post',
        headers: {
          path: 'orders/reorder',
          token: context.store.state.userToken.ordering.access.token
        },
        data: {
          id: id
        }
      })
    },
    async saveFave(id, name) {
      return this.request({
        method: 'post',
        headers: {
          path: 'orders/faves',
          token: context.store.state.userToken.ordering.access.token
        },
        data: {
          id: id,
          description: name
        }
      })
    },
    async getFaves() {
      return this.request({
        method: 'get',
        headers: {
          path: 'orders/faves',
          token: context.store.state.userToken.ordering.access.token
        }
      })
    },
    async reorderFave(id) {
      return this.request({
        method: 'post',
        headers: {
          path: 'orders/faves/reorder',
          token: context.store.state.userToken.ordering.access.token
        },
        data: {
          id: id
        }
      })
    },
    async createOrder(mid) {
      let headers = {
        path: 'orders/create'
      }
      if(context.store.state.userToken) {
        headers.token = context.store.state.userToken.ordering.access.token
      }
      return this.request({
        method: 'post',
        headers: headers,
        data: {
          menu_id: mid
        }
      })
    },
    async retrieveOrder(oid) {
      return this.request({
        method: 'get',
        headers: {
          path: `orders/${oid}`
        }
      })
    },
    async addToOrder(oid, prods) {
      return this.request({
        method: 'post',
        headers: {
          path: `orders/${oid}/products`
        },
        data: {
          products: prods
        }
      })
    },
    async updateInOrder(oid, pid, prods) {
      return this.request({
        method: 'put',
        headers: {
          path: `orders/${oid}/products/${pid}`
        },
        data: prods
      })
    },
    async removeFromOrder(oid, pid) {
      return this.request({
        method: 'delete',
        headers: {
          path: `orders/${oid}/products/${pid}`
        }
      })
    },
    async validateOrder(oid) {
      return this.request({
        method: 'post',
        headers: {
          path: `orders/${oid}/validate`
        }
      })
    },
    async getPayments(oid) {
      return this.request({
        method: 'get',
        headers: {
          path: `members/payments`,
          token: context.store.state.userToken.ordering.access.token
        }
      })
    },
    async deletePayment(pid) {
      return this.request({
        method: 'delete',
        headers: {
          path: `members/payments/${pid}`,
          token: context.store.state.userToken.ordering.access.token
        }
      })
    },
    async giftCardBalance(oid, num, pin) {
      return this.request({
        method: 'post',
        headers: {
          path: `orders/${oid}/giftcard`
        },
        data: {
          number: num,
          pin: pin
        }
      }, true)
    },
    async deleteAddress(aid) {
      return this.request({
        method: 'delete',
        headers: {
          path: `members/addresses/${aid}`,
          token: context.store.state.userToken.ordering.access.token
        }
      })
    },
    async setHandoff(oid, mode) {
      return this.request({
        method: 'put',
        headers: {
          path: `orders/${oid}/handoff/${mode}`
        }
      })
    },
    async setAddress(oid, type, address) {
      return this.request({
        method: 'put',
        headers: {
          path: `orders/${oid}/address`
        },
        data: {
          type: type,
          address: address
        }
      })
    },
    async setCustomFields(oid, fields) {
      return this.request({
        method: 'put',
        headers: {
          path: `orders/${oid}/custom`
        },
        data: {
          fields: fields
        }
      })
    },
    async getCalendar(mid, from, to) {
      return this.request({
        method: 'get',
        headers: {
          path: `menus/${mid}/times`
        },
        params: {
          from: from,
          to: to
        }
      })
    },
    async setTime(oid, time) {
      return this.request({
        method: 'put',
        headers: {
          path: `orders/${oid}/time`
        },
        data: {
          time: time
        }
      })
    },
    async deleteTime(oid) {
      return this.request({
        method: 'delete',
        headers: {
          path: `orders/${oid}/time`
        }
      })
    },
    async addTip(oid, amount) {
      return this.request({
        method: 'put',
        headers: {
          path: `orders/${oid}/tip`
        },
        data: {
          amount: amount
        }
      })
    },
    async addCoupon(oid, code) {
      return this.request({
        method: 'put',
        headers: {
          path: `orders/${oid}/coupon`
        },
        data: {
          code: code
        }
      })
    },
    async removeCoupon(oid) {
      return this.request({
        method: 'delete',
        headers: {
          path: `orders/${oid}/coupon`
        }
      })
    },
    async getUpsells(oid) {
      return this.request({
        method: 'get',
        headers: {
          path: `upsells/${oid}`
        }
      })
    },
    async addUpsells(oid, items) {
      return this.request({
        method: 'post',
        headers: {
          path: `upsells/${oid}`
        },
        data: {
          items
        },
        upsells: true
      })
    },
    async getBasketToken(oid) {
      let headers = {
        path: `orders/${oid}/token`
      }
      if(context.store.state.userToken && context.store.state.userToken.ordering) {
        headers.token = context.store.state.userToken.ordering.access.token
      }
      return this.request({
        method: 'post',
        headers: headers
      })
    },
    async submitOrder(oid, billing) {
      let headers = {
        path: `orders/${oid}/submit`
      }
      let data = {
        usertype: 'guest',
        payments: billing
      }
      if(context.store.state.userToken && context.store.state.userToken.ordering) {
        data.usertype = 'user'
        headers.token = context.store.state.userToken.ordering.access.token
      } else {
        data.first_name = context.store.state.guestInfo.firstName
        data.last_name = context.store.state.guestInfo.lastName
        data.email = context.store.state.guestInfo.email
        data.phone = context.store.state.guestInfo.phone
      }
      return this.request({
        method: 'post',
        headers: headers,
        data: data
      })
    },
    trackOrder(oid, cid, products) {
      return this.request({
        method: 'post',
        headers: {
          path: `orders/${oid}/track`
        },
        data: {
          id: cid,
          products
        }
      })
    },
    trackPaymentFailure(oid, error) {
      return this.request({
        method: 'post',
        headers: {
          path: `orders/${oid}/track`
        },
        data: {
          error,
          type: 'ccsf'
        }
      })
    },
    async getOrder(oid) {
      return this.request({
        method: 'get',
        headers: {
          path: `orders/${oid}/status`
        }
      })
    },
    async cancelOrder(oid) {
      return this.request({
        method: 'delete',
        headers: {
          path: `orders/${oid}`
        }
      })
    },
    async abandonOrder(id) {
      return this.request({
        method: 'delete',
        headers: {
          path: `orders/${id}/abandon`
        }
      })
    },
    getOffers() {
      return this.request({
        method: 'get',
        headers: {
          path: `members/offers/all`,
          token: context.store.state.userToken.access.token
        },
      })
    },
    getBalance() {
      // console.log(context.store)
      return this.request({
        method: 'get',
        headers: {
          path: `members/balance`,
          token: context.store.state.userToken.access.token
        },
      })
    },
    getRedeemedOffers() {
      return this.request({
        method: 'post',
        headers: {
          path: `members/balance?memberID=${context.store.state.userToken.id}`,
          token: context.store.state.userToken.access.token
        },
      }, false, false, false)
    },
    redeemOfferToAccount(price, code) {
      return this.request({
        method: 'post',
        headers: {
          path: `members/redeem?memberID=${context.store.state.token.id}`,
          token: context.store.state.userToken.access.token
        },
        data: {
          price: price,
          code: code
        }
      }, false, false, false)
    },
    getOffersForOrder() {
      if (context.store.state.token) {
        return this.request({
          method: 'get',
          headers: {
            path: `orders/${context.store.state.order.id}/rewards`,
            token: context.store.state.userToken.access.token
          }
        })
      } else {
        return []
      }
    },
    async applyOfferToOrder(memberid, reference) {
      const order = await this.request({
        method: 'put',
        headers: {
          path: `orders/${context.store.state.order.id}/rewards`,
          token: context.store.state.userToken.access.token
        },
        data: {
          memberid: memberid,
          references: [
            reference
          ]
        }
      })
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async removeOfferFromOrder(id) {
      const order = await this.request({
        method: 'delete',
        headers: {
          path: `orders/${context.store.state.order.id}/rewards/${id}`,
          token: context.store.state.userToken.access.token
        }
      })
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    addReferralToBasket(refferal) {
        // console.log('context.store.state.order::::: ', context.store.state.order) //apparently there is no order on state??????
        // console.log('context.store.state.activeOrder::::: ', context.store.state.activeOrder)
        if (context.store.state.activeOrder) {
            return this.request({
                method: "put",
                headers: {
                    path: `orders/${context.store.state.activeOrder.id}/referrals`,
                },
                data: {
                    referrals: refferal,
                },
            });
        }
    },
 }

  inject('api', api)
  context.$api = api
}