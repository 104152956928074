import StoryblokClient from 'storyblok-js-client'

const Storyblok = new StoryblokClient({
  accessToken: process.env.STORYBLOK_KEY
})

export default (context, inject) => {
  const storyblok = {
    async getAllPages(type) {
      let all = []
      let stories = []
      let page = 1
      do {
        stories = await this.getPages(type, page)
        all = all.concat(stories)
        page++
      } while(stories.length >= 100)
      return all
    },
    async getPages(type, page = 1, per_page = 100) {
      return Storyblok.get('cdn/stories', {
        starts_with: type,
        version: process.env.STORYBLOK_VERSION || 'draft',
        page: page,
        per_page: per_page
      })
        .then(res => {
          return res.data.stories
        })
        .catch(err => {
          return null
        })
    },
    async getPage(slug) {
      if(process.server && process.env.NODE_ENV != 'development') {
        if(slug == 'pages/') {
          slug = 'pages/home'
        }
        return await context.$storage.getFile(slug)
      } else if(process.env.DEV) {
        return Storyblok.get(`cdn/stories/${slug}`, {
          version: process.env.STORYBLOK_VERSION || 'draft',
        })
          .then(res => {
            return res.data.story
          })
          .catch(err => {
            console.log('requested page', slug)
            return null
          })
      } else {
        if(slug == 'pages/') {
          slug = 'pages/home'
        }
        return await context.$axios({
          method: 'get',
          url: `${window.location.origin}/_data/${slug}.json`
        })
          .then(res => {
            return res.data
          })
          .catch(err => {
            return null
          })
      }
    }
  }

  inject('storyblok', storyblok)
  context.$storyblok = storyblok
}