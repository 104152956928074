//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'

export default {
  data() {
    return {
      sm: ''    }
  },
  mounted() {
    this.$nextTick(function () {
      this.checkMobile()
    })
    window.addEventListener('resize', this.checkMobile)
  },
  computed: {
    aboutMenu() {
      return this.$store.state.menus['about-us'] ? this.$store.state.menus['about-us'].content.links : []
    },
    resourcesMenu() {
      return this.$store.state.menus.resources ? this.$store.state.menus.resources.content.links : []
    },
    legalMenu() {
      return this.$store.state.menus.legal ? this.$store.state.menus.legal.content.links : []
    },
    socialMenu() {
      return this.$store.state.menus.social ? this.$store.state.menus.social.content.links : []
    },
    copyright() {
      return `© ${moment().format('YYYY')} Perkins LLC All rights reserved.`
    }
  },
  methods: {
    checkMobile() {
      this.sm = (window.innerWidth < 640) ? 1 : 0
    },
    scrubLink(link) {
      return link.replace('pages', '')
    }
  }
}
