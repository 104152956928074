//
//
//
//
//
//
//
//

import { TheMask } from 'vue-the-mask'

export default {
 props: {
    context: {
      type: Object,
      required: true
    },
  },
  components: {
    TheMask,
  },
}
