//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import { TheMask } from 'vue-the-mask'

export default {
  components: { TheMask },
  props: [
    'content'
  ],
  data() {
    return {
      searched: false,
      locations: [],
      supportsLoyalty: true,
      termsErr: false,
      showModal: true,
      bday: null,
      zip: null,
      favLoc: null,
      terms: false,
      fname: '',
      lname: '',
      email: '',
      phone: null,
      psw: '',
      pswConfirm: '',
      errorMessage: null,
    }
  },
  mounted() {
    console.log(this.user)
    this.fname = this.user.first_name
    this.lname = this.user.last_name
    this.email = this.user.email

    if (this.user.phone) {
      this.phone = this.user.phone
    }
  },
  computed: {
    formStyle() {
      return 'w-full w-full block border py-2 px-4 my-4 text-black rounded-sm'
    },
    orderType() {
      return this.$store.state.orderType
    },
    heading() {
      return 'Please Confirm Your Details Below'
    },
    user() {
      return this.$store.state.activeUser
    },
    // fname() {
    //   return this.user.first_name
    // }
  },
  methods: {
    showPassword() {
      console.log('test')
      let psw = document.getElementById("psw");
      let pswConfirm = document.getElementById("pswConfirm");

      if (psw.type === "password") {
        psw.type = "text";
      } else {
        psw.type = "password";
      }

      if (pswConfirm.type === "password") {
        pswConfirm.type = "text";
      } else {
        pswConfirm.type = "password";
      }
    },
    async checkLoyalty() {
      let location = await this.$api.getLocation(this.favLoc)
      if (location.loyalty === false) {
        this.supportsLoyalty = false
      } else {
        this.supportsLoyalty = true
      }
    },
    async updateUser() {
      if (this.supportsLoyalty == true) {
        this.termsErr = false
        this.errorMessage = null
        let data = {
          loyalty: true
        }

        // Validate First Name
        if(this.fname.match(/^[a-zA-Z]+/)) {
          data.first_name = this.fname
        } else {
          this.errorMessage = 'Please enter your first name.'
          return
        }

        // Validate Last Name
        if(this.lname.match(/^[a-zA-Z]+/)) {
          data.last_name = this.lname
        } else {
          this.errorMessage = 'Please enter your last name.'
          return
        }

        // Validate Email
        if(this.email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
          data.email = this.email
        } else {
          this.errorMessage = 'Please enter a valid email.'
          return
        }

        // Validate phone number
        if(this.phone && this.phone.match(/^\d{10}$/)) {
          data.phone = this.phone
        } else {
          this.errorMessage = 'Please enter a valid 10 digit phone number'
          return
        }

        // Validate Password Length
        if(this.psw.length >= 8) {
          //Validate Password Match
          if (this.psw === this.pswConfirm) {
            data.password = this.psw
          } else {
            this.errorMessage = 'Your passwords do not match'
            return
          }
        } else {
          this.errorMessage = 'Your Password must be at least 8 characters in length'
          return
        }

        // Validate Bday
        if(moment(this.bday, 'YYYY-MM-DD',true).isValid()) {
          data.dob = moment(this.bday, 'YYYY-MM-DD').format('YYYY-MM-DD')
        } else {
          this.errorMessage = 'Please enter your birthdate in MM/DD/YYYY format.'
          return
        }

        // Validate Zip
        if(this.zip.match(/\d{5}/)) {
          data.zip = this.zip
        } else {
          this.errorMessage = 'Please enter your zipcode.'
          return
        }

        // Validate Fav Location
        if(this.favLoc && this.favLoc.toString().match(/\d{3,6}/)) {
          data.favelocation = this.favLoc
        } else {
          this.errorMessage = 'Please select a preferred store.'
          return
        }

        data.optin = true

        if (this.terms === false) {
          this.termsErr = true
          this.errorMessage = 'To move forward you will need to agree to our terms and privacy policy.'
          return
        }

        console.log(data)

        this.$store.commit('setLoading', true)
        let create = await this.$api.memberSignup(data)
        if(create) {
          let userData = await this.$api.memberSignin(data.email, data.password)
          if(userData) {
            this.$store.commit('setUserToken', userData.tokens)
            let user = await this.$api.getMemberProfile()
            this.$store.commit('setActiveUser', user)
            if(this.$store.state.redirect) {
              let redirect = this.$store.state.redirect
              this.$store.commit('setRedirect', null)
              this.$router.push(redirect)
            } else {
              this.$router.push('/account/perks')
            }
          }
        } else {
          // API error, probably the user already exists
        }
        this.showModal = false
        this.$store.commit('setLoyaltyOptInSelector', false)
        this.$store.commit('setLoading', false)
      }
    },
    async search() {
      this.$store.commit('setLoading', true)
      let locations = await this.$api.findMemberLocations({ zip: this.zip})
      this.searched = true
      this.$store.commit('setLoading', false)
      if(locations && locations.count > 0) {
        let locs = []
        locations.locations.map(l => {
          locs.push({
            value: l.sid,
            name: `${l.address}, ${l.city}, ${l.state}`
          })
        })
        this.locations = locs
      }
    },
    close() {
      this.showModal = false
      setTimeout(this.remove, 1000)
      
    },
    remove() {
      this.$store.commit('setLoyaltyOptInSelector', false)
    }
  }
  }
