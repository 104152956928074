//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import MobileStickyForm from '~/components/franchising/MobileStickyForm.vue'

export default {
  components: { MobileStickyForm },
  data() {
    return {
      slug: '',
      loading: true
    }
  },
  computed: {
    loc() {
      return this.$store.state.location
    },
    menu() {
      return this.$store.state.activeMenu
    },
    showCart() {
      return this.$store.state.globalCart
    },
    showSelector() {
      return this.$store.state.locationSelector
    },
    showFaveSelector() {
      return this.$store.state.faveLocationSelector
    },
    showLoader() {
      return this.$store.state.loading
    },
    errors() {
      return this.$store.state.activeErrors
    },
    screen() {
      return 'fixed inset-0 bg-white-80 flex items-center justify-center z-20'
    },
    screenclear() {
      return 'fixed inset-0 flex items-center justify-center z-20'
    }
  },
  beforeMount() {
    setTimeout(function() {
      this.$nextTick(function () {
        let path = this.$route.fullPath
        path = path.toLowerCase().split('#')[0].split('?')[0].replace(/ /g,'-').replace(/[^\w-]+/g,'')
        if (path == '') {
          this.slug = 'home'
        } else {
          this.slug = path
        }
        this.loading = true
      })
    }.bind(this), 100)
  },
  async mounted() {
    try {
      await this.$recaptcha.init()
    } catch(err) {
      console.error(err)
    }
    window.addEventListener('scroll',this.handleScroll)
    this.$root.$on('loaded', data => {
      console.log(data);
    });
    
    
    if(this.$route.query.code) {
      this.$store.commit('setLoading', true)
      let userData = await this.$api.memberCodeExchange(this.$route.query.code)
      if(userData) {
        console.log(userData)
        this.$store.commit('setUserToken', userData.tokens)
        let user = await this.$api.getMemberProfile()
        this.$store.commit('setActiveUser', user)
      }
      this.$store.commit('setLoading', false)
      if(this.$store.state.redirect) {
        this.$router.push(this.$store.state.redirect)
        this.$store.commit('setRedirect', null)
      }
    }

    if(this.$route.query.store) {
      let loc = await this.$api.getLocation(this.$route.query.store, true)
      if(loc) {
        this.$store.commit('setLocation', loc)
        if(loc.menu) {
          this.$store.commit('setActiveMenu', loc.menu)
          this.$store.commit('setActiveOrder', null)
        }
      }
    } else if(this.loc && this.loc.menu_id && this.menu /*&& moment(this.menu.expires, 'LLLL') < moment()*/) {
      let loc = await this.$api.getLocation(this.loc.id, true)
      if(loc) {
        this.$store.commit('setLocation', loc)
        if(loc.menu) {
          this.$store.commit('setActiveMenu', loc.menu)
        }
      }
    }

    // check/re-load locations/menus
    if(this.$route.name.match(/^order/) || this.$route.name.match(/^menu/)) {
      if(!this.loc) {
        this.$store.commit('setLocationSelector', true)
      }
    }
  },
  destroyed() {
    window.removeEventListener('scroll',this.handleScroll)
  },
  watch: {
    '$route.path': function(e) {
      window.scroll({top: 0, left: 0, behavior: 'smooth'})
      this.loading = true
      let path = this.$route.fullPath
      path = path.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'').split('#')[0]
      if (path == '') {
        this.slug = 'home'
      } else {
        this.$nextTick(() => {
          this.slug = path
        })
      }
      if(this.$route.name && (this.$route.name.match(/^order/) || this.$route.name.match(/^menu/)) && !this.loc) {
        this.$store.commit('setLocationSelector', true)
      } else {
        this.$store.commit('setLocationSelector', false)
      }
    }
  },
  methods: {
    handleScroll() {
      let bgs = document.querySelectorAll('.parallax > div')
      let rate = 0.023
      for (var i = bgs.length - 1; i >= 0; i--) {
        let px = (window.scrollY*rate)+'px'
        if (bgs[i].parentElement.classList.contains('reverse')) {
          bgs[i].style.transform = 'translateY(-'+px+') scale(1.3)'
        } else {
          bgs[i].style.transform = 'translateY('+px+') scale(1.3)'
        }
      }
    }
  }
}
