import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _31186d84 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _484494f6 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _0533bca3 = () => interopDefault(import('../pages/franchising/index.vue' /* webpackChunkName: "pages/franchising/index" */))
const _16ddd532 = () => interopDefault(import('../pages/locations/index.vue' /* webpackChunkName: "pages/locations/index" */))
const _5b71908c = () => interopDefault(import('../pages/menu/index.vue' /* webpackChunkName: "pages/menu/index" */))
const _2c62ff52 = () => interopDefault(import('../pages/order/index.vue' /* webpackChunkName: "pages/order/index" */))
const _ccc0c8e4 = () => interopDefault(import('../pages/perks/index.vue' /* webpackChunkName: "pages/perks/index" */))
const _5139390c = () => interopDefault(import('../pages/signin.vue' /* webpackChunkName: "pages/signin" */))
const _038df16c = () => interopDefault(import('../pages/account/forgot-password/index.vue' /* webpackChunkName: "pages/account/forgot-password/index" */))
const _4d4f2cb8 = () => interopDefault(import('../pages/account/history.vue' /* webpackChunkName: "pages/account/history" */))
const _c472342e = () => interopDefault(import('../pages/account/perks.vue' /* webpackChunkName: "pages/account/perks" */))
const _77f4b6a4 = () => interopDefault(import('../pages/account/signup.vue' /* webpackChunkName: "pages/account/signup" */))
const _2fd77c50 = () => interopDefault(import('../pages/app/confirm.vue' /* webpackChunkName: "pages/app/confirm" */))
const _77bc7a1e = () => interopDefault(import('../pages/app/payment.vue' /* webpackChunkName: "pages/app/payment" */))
const _8f84c834 = () => interopDefault(import('../pages/app/payment-dev.vue' /* webpackChunkName: "pages/app/payment-dev" */))
const _636f55a4 = () => interopDefault(import('../pages/franchising/blog/index.vue' /* webpackChunkName: "pages/franchising/blog/index" */))
const _c7a1a85e = () => interopDefault(import('../pages/order/checkout.vue' /* webpackChunkName: "pages/order/checkout" */))
const _98d05ff6 = () => interopDefault(import('../pages/order/confirm.vue' /* webpackChunkName: "pages/order/confirm" */))
const _462806b4 = () => interopDefault(import('../pages/order/product.vue' /* webpackChunkName: "pages/order/product" */))
const _19dc3e26 = () => interopDefault(import('../pages/order/signin.vue' /* webpackChunkName: "pages/order/signin" */))
const _0558a266 = () => interopDefault(import('../pages/account/forgot-password/_code.vue' /* webpackChunkName: "pages/account/forgot-password/_code" */))
const _61b8b45c = () => interopDefault(import('../pages/franchising/blog/_slug.vue' /* webpackChunkName: "pages/franchising/blog/_slug" */))
const _037d1b5b = () => interopDefault(import('../pages/franchising/_slug.vue' /* webpackChunkName: "pages/franchising/_slug" */))
const _d02e0b74 = () => interopDefault(import('../pages/perks/_slug.vue' /* webpackChunkName: "pages/perks/_slug" */))
const _918f77c2 = () => interopDefault(import('../pages/locations/_country/_state/_city/_location.vue' /* webpackChunkName: "pages/locations/_country/_state/_city/_location" */))
const _08b4f1e0 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _31186d84,
    name: "404"
  }, {
    path: "/account",
    component: _484494f6,
    name: "account"
  }, {
    path: "/franchising",
    component: _0533bca3,
    name: "franchising"
  }, {
    path: "/locations",
    component: _16ddd532,
    name: "locations"
  }, {
    path: "/menu",
    component: _5b71908c,
    name: "menu"
  }, {
    path: "/order",
    component: _2c62ff52,
    name: "order"
  }, {
    path: "/perks",
    component: _ccc0c8e4,
    name: "perks"
  }, {
    path: "/signin",
    component: _5139390c,
    name: "signin"
  }, {
    path: "/account/forgot-password",
    component: _038df16c,
    name: "account-forgot-password"
  }, {
    path: "/account/history",
    component: _4d4f2cb8,
    name: "account-history"
  }, {
    path: "/account/perks",
    component: _c472342e,
    name: "account-perks"
  }, {
    path: "/account/signup",
    component: _77f4b6a4,
    name: "account-signup"
  }, {
    path: "/app/confirm",
    component: _2fd77c50,
    name: "app-confirm"
  }, {
    path: "/app/payment",
    component: _77bc7a1e,
    name: "app-payment"
  }, {
    path: "/app/payment-dev",
    component: _8f84c834,
    name: "app-payment-dev"
  }, {
    path: "/franchising/blog",
    component: _636f55a4,
    name: "franchising-blog"
  }, {
    path: "/order/checkout",
    component: _c7a1a85e,
    name: "order-checkout"
  }, {
    path: "/order/confirm",
    component: _98d05ff6,
    name: "order-confirm"
  }, {
    path: "/order/product",
    component: _462806b4,
    name: "order-product"
  }, {
    path: "/order/signin",
    component: _19dc3e26,
    name: "order-signin"
  }, {
    path: "/account/forgot-password/:code?",
    component: _0558a266,
    name: "account-forgot-password-code"
  }, {
    path: "/franchising/blog/:slug",
    component: _61b8b45c,
    name: "franchising-blog-slug"
  }, {
    path: "/franchising/:slug",
    component: _037d1b5b,
    name: "franchising-slug"
  }, {
    path: "/perks/:slug",
    component: _d02e0b74,
    name: "perks-slug"
  }, {
    path: "/locations/:country/:state?/:city?/:location?",
    component: _918f77c2,
    name: "locations-country-state-city-location"
  }, {
    path: "/:slug?",
    component: _08b4f1e0,
    name: "slug"
  }],

  fallback: false
}

export function createRouter () {
  const router = new Router(routerOptions)
  const resolve = router.resolve.bind(router)

  // encodeURI(decodeURI()) ~> support both encoded and non-encoded urls
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = encodeURI(decodeURI(to))
    }
    return resolve(to, current, append)
  }

  return router
}
