import Vue from 'vue'
import VueRichTextRenderer from '@marvr/storyblok-rich-text-vue-renderer'
import FormField from '@/components/form/FormField.vue'
// import VueFilterTruncate from '@vuejs-community/vue-filter-truncate'
// Vue.use(VueFilterTruncate);
Vue.use(VueRichTextRenderer, {
  resolvers: {
    components: {
        FormField 
     },
  },
})
