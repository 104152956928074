//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: [
    'content'
  ],
  computed: {
    orderType() {
      return this.$store.state.orderType
    },
    heading() {
      return this.orderType == 'delivery' ? 'Where would you like your food delivered?' : 'Find a Perkins location near you'
    },
    loc() {
      return this.$store.state.location
    },
    user() {
      return this.$store.state.activeUser
    }
  },
  data() {
    return {
      showModal: true,
      results: true,
      locations: [],
      searching: false,
      searched: false,
      searchTerm: null,
      city: null,
      zipcode: null
    }
  },
  methods: {
    findme() {
      if(navigator.geolocation) {
        this.searching = true
        navigator.geolocation.getCurrentPosition(this.getCoordinates)
      } else {
        this.$store.commit('setActiveErrors', ['Please enable location to use this feature.'])
      }
    },
    async getCoordinates(position) {
      let coords = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }
      let search = await this.$api.findLocations(coords)
      this.locations = search.locations
      this.searching = false
      this.searched = true
    },
    getCoordinatesError() {
      this.searching = false
      this.$store.commit('setActiveErrors', ['Please enable location to use this feature.'])
    },
    async search() {
      if(this.searchTerm) {
        let searchTerm = this.searchTerm
        if(this.orderType == 'delivery') {
          searchTerm += `, ${this.city}, ${this.zipcode}`
        }
        this.searching = true
        this.$nuxt.$loading.start()
        let search = await this.$api.findLocations({ address: searchTerm })
        let locations = []
        if (search && search.locations) {
          search.locations.map(l => {
            // if(l.closed == false || l.id == 'OLO') {
              locations.push(l)
            // }
          })
        }
        this.locations = locations
        this.searching = false
        this.searched = true
        this.$nuxt.$loading.finish()
      }
    },
    async startOrder(l) {
      console.log(this.$route)
      if(!l.menu_id) {
        // let slug = l.address.toLowerCase().replace(/\s/g, '-').replace(/\./g, '-').replace(/\'/g, '-').replace(/\//g, '-').replace(/\-+$/, '')
        // let city = l.city.toLowerCase().replace(/\s/g, '-').replace(/\./g, '-')
        // let url = `locations/${l.country.toLowerCase()}/${l.state.toLowerCase()}/${city}/${slug}`
        // this.$router.push(url)
        this.$store.commit('setLocation', l)
        this.$store.commit('setLoading', true)
        let menu = await this.$api.getMenu(process.env.DEFAULT_MENU)
        if(menu) {
          this.$store.commit('setActiveMenu', menu)
          this.$store.commit('setLoading', false)
          this.$store.commit('setActiveOrder', null)
          if(this.$route.name == 'menu' || this.$route.name == 'order') {
            window.location.reload()
          } else {
            this.$router.push('/menu')
          }
          this.close()
        } else {
          this.$store.commit('setLoading', true)
          // this.$store.commit('setActiveErrors', ['Connection issue. Please try again'])
        }
      } else {
        this.$store.commit('setLocation', l)
        this.$store.commit('setLoading', true)
        if(this.orderType == 'delivery' && !l.services.delivery && !l.services.dispatch) {
          this.$store.commit('setOrderType', 'pickup')
        } 
        //else if(this.orderType == 'delivery') {
        //   this.$store.commit('setOrderAddress', this.searchTerm)
        // }
        let menu = await this.$api.getMenu(l.menu_id)
        if(menu) {
          this.$store.commit('setActiveMenu', menu)
          let order = await this.$api.createOrder(l.menu_id)
          if(order) {
            if(this.orderType == 'delivery') {
              let address = {
                address: this.searchTerm,
                city: this.city,
                zip: this.zipcode
              }
              let type = l.services.delivery ? 'delivery' : 'dispatch'
              order = await this.$api.setAddress(order.id, type, address)
            }
            this.$store.commit('setActiveOrder', order)
          }
          if(this.$route.name === 'order-checkout') this.$router.push('/order')
          // this.$router.push('/order')
          if(this.$route.name == 'menu' || this.$route.name == 'order') {
            window.location.reload()
          } else {
            this.$store.commit('setLoading', false)
            this.close()
            this.$router.push('/order')
          }
        } else {
          this.$store.commit('setLoading', true)
          // this.$store.commit('setActiveErrors', ['Connection issue. Please try again'])
        }
      }
    },
    close() {
      // Prevent and redirect if on page that requires location
      this.showModal = false
      setTimeout(this.remove, 1000)
      if((this.$route.name.match(/^order/) || this.$route.name.match(/^menu/)) && !this.loc) {
        this.$router.push('/')
      }
      
    },
    remove() {
      this.$store.commit('setLocationSelector', false)
    }
  }
}
