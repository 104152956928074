import moment from 'moment'
import axios from 'axios'
import StoryblokClient from 'storyblok-js-client'
import { flatten } from 'lodash';

const Storyblok = new StoryblokClient({
  accessToken: process.env.STORYBLOK_KEY
})


export const state = () => ({
  env: 'prod',
  clientType: 'web',
  menus: {},
  page: {},
  loading: false,
  activeErrors: [],
  locations: [],
  locationSelector: false,
  faveLocationSelector: false,
  faveLocationLoyalty: false,
  loyaltyOptInSelector: false,
  faveLocationLoyalty: false,
  loyaltySignupOptIn: false,
  globalCart: false,
  location: null,
  defaultMenu: null,
  defaultMenus: [],
  activeMenu: null,
  activeOrder: null,
  giftcard: {
    number: null,
    pin: null,
    balance: 0
  },
  activeUser: null,
  guestInfo: {
    firstName: null,
    lastName: null,
    email: null,
    phone: null
  },
  userToken: null,
  userPayments: [],
  redirect: null,
  updatePayments: false,
  orderType: null,
  orderAddress: null,
  nonQuickAdds:{},
  supercategories: [],
  enableSubmit: true,
  rewardCode: null,
})

export const mutations = {
  setEnv(state, string) {
    state.env = string
  },
  setClientType(state, string) {
    state.clientType = string
  },
  setMenus(state, menus) {
    state.menus = menus
  },
  setNonQuickAdds(state, data) {
    const body = data[0].content.body
    state.nonQuickAdds = body ? body[0] : {}
  },  
  setPage(state, page) {
    state.page = page
  },

  setLoading(state, bool) {
    state.loading = bool
  },

  setActiveErrors(state, arr) {
    state.activeErrors = arr
  },

  setLocations(state, arr) {
    state.locations = arr
  },

  setLocationSelector(state, bool) {
    state.locationSelector = bool
  },

  setFaveLocationSelector(state, bool) {
    state.faveLocationSelector = bool
  },

  setFaveLocationLoyalty(state, bool) {
    state.faveLocationLoyalty = bool
  },

  setLoyaltyOptInSelector(state, bool) {
    state.loyaltyOptInSelector = bool
  },

  setLoyaltySignupOptIn(state, bool) {
    state.loyaltySignupOptIn = bool
  },

  setFaveLocationLoyalty(state, bool) {
    state.faveLocationLoyalty = bool
  },

  setGlobalCart(state, bool) {
    state.globalCart = bool
  },
  
  setLocation(state, loc) {
    if(loc == null) {
      localStorage.removeItem('pk_loc')
    } else {
      loc.expires = moment().add(1, 'day').format('LLLL')
      localStorage.setItem('pk_loc', JSON.stringify(loc))
    }
    state.location = loc
  },

  setDefaultMenu(state, menu) {
    state.defaultMenu = menu
  },

  setDefaultMenus(state, menus) {
    state.defaultMenus = menus
  },

  setActiveMenu(state, menu) {
    if(menu == null) {
      localStorage.removeItem('pk_menu')
    } else {
    //   menu.expires = moment().add(1, 'hour').format('LLLL')
    if (menu.expires_ignore  === undefined || menu.expires_ignore === false) {
        menu.expires = moment().add(1, 'hour').format('LLLL')
    }
        localStorage.setItem('pk_menu', JSON.stringify(menu))
    }
    state.activeMenu = menu
  },

  setActiveOrder(state, order) {
    if(order == null) {
      localStorage.removeItem('pk_order')
    } else {
      order.expires = moment().add(1, 'day').format('LLLL')
      localStorage.setItem('pk_order', JSON.stringify(order))
    }
    state.activeOrder = order
  },

  setGiftcard(state, card) {
    if(card == null) {
      state.giftcard = {
        number: null,
        pin: null,
        balance: 0
      }
    } else {
      state.giftcard = card
    }
  },

  setActiveUser(state, user) {
    if(user == null) {
      localStorage.removeItem('pk_user')
    } else {
      localStorage.setItem('pk_user', JSON.stringify(user))
    }
    state.activeUser = user
  },

  setGuestInfo(state, guest) {
    if(guest == null) {
      state.guestInfo = {
        firstName: null,
        lastName: null,
        email: null,
        phone: null
      }
    } else {
      state.guestInfo = guest
    }
  },

  setUserToken(state, token) {
    if(token == null) {
      localStorage.removeItem('pk_token')
      localStorage.removeItem('pk_user')
      state.activeUser = null
    } else {
      localStorage.setItem('pk_token', JSON.stringify(token))
    }
    state.userToken = token
  },

  setUserPayments(state, payments) {
    state.userPayments = payments
  },

  setRedirect(state, addr) {
    state.redirect = addr
    try {
      if(addr == null) {
        localStorage.removeItem('pk_redirect')
      } else {
        localStorage.setItem('pk_redirect', addr)
      }
    } catch(err) {
      // ignore
    }
  },

  setUpdatePayments(state, bool) {
    state.updatePayments = bool
  },

  setOrderType(state, string) {
    state.orderType = string
  },

  setOrderAddress(state, string) {
    state.orderAddress = string
  },

  setSupercategories(state, arr) {
    state.supercategories = arr
  },

  setEnableSubmit(state, bool) {
    state.enableSubmit = bool
  },
  
  setRewardCode(state, code) {
    state.rewardCode = code
  }
}

export const actions = {
  async nuxtServerInit({ commit }, context) {
    if(process.env.API_ENV) {
      commit('setEnv', process.env.API_ENV)
    }
    await initMenus(commit)
    await initLocations(commit)
    await initDefaultMenu(commit)
  },
  async nuxtClientInit({ commit }, context) {
    if(!process.env.RENDER) {
      await initMenus(commit)
    }
    if(localStorage) {
      if(localStorage.getItem('pk_loc')) {
        commit('setLocation', JSON.parse(localStorage.getItem('pk_loc')))
      }
      if(localStorage.getItem('pk_menu')) {
        let menu = JSON.parse(localStorage.getItem('pk_menu'))
        menu.expires_ignore = true
        commit('setActiveMenu', menu)
        // commit('setActiveMenu', JSON.parse(localStorage.getItem('pk_menu')))
      }
      if(localStorage.getItem('pk_order')) {
        commit('setActiveOrder', JSON.parse(localStorage.getItem('pk_order')))
      }
      if(localStorage.getItem('pk_user')) {
        commit('setActiveUser', JSON.parse(localStorage.getItem('pk_user')))
      }
      if(localStorage.getItem('pk_token')) {
        commit('setUserToken', JSON.parse(localStorage.getItem('pk_token')))
      }
      if(localStorage.getItem('pk_redirect')) {
        commit('setRedirect', localStorage.getItem('pk_redirect'))
      }
    }
  }
}

async function initMenus(commit) {
  let stories = await Storyblok.get('cdn/stories', {
    starts_with: 'globals',
    version: process.env.STORYBLOK_VERSION || 'draft'
  })
    .then(res => {
      return res.data.stories
    })
  let menus = {}
  stories.map(s => {
    if(s.content.component == 'menu') {
      menus[s.slug.replace('-menu', '')] = s
    }
  })
  const asItComes = stories.filter(s => s.slug === 'as-it-comes')
  commit('setNonQuickAdds', asItComes) 
  commit('setMenus', menus)
  let supercats = await Storyblok.get('cdn/stories/globals/super-categories', {
      version: process.env.STORYBLOK_VERSION || 'draft'
    })
    .then(res => {
      return res.data.story.content.super_cats
    })
  let supcats = []
  supercats.map(s => {
    let sup = {
      name: s.name,
      subcategories: []
    }
    s.sub_categories.map(b => {
      let sub = {
        name: b.name,
        id: []
      }
      b.id.toString().split(',').map(i => {
        sub.id.push(parseInt(i))
      })
      sup.subcategories.push(sub)
    })
    supcats.push(sup)
  })
  commit('setSupercategories', supcats)
  return
}
 
async function initLocations(commit) {
  let all = []
  let stories = []
  let page = 1
  do {
    stories = await Storyblok.get('cdn/stories', {
      starts_with: 'locations',
      version: 'published',
      page: page,
      per_page: 100
    })
      .then(res => {
        return res.data.stories
      })
    all = all.concat(stories)
    page++
  } while(stories.length >= 100)
  let locations = []
  all.map(l => {
    locations.push({
      content: {
        name: l.content.city,
        id: l.content.store_num,
        menu_id: l.content.menu_id,
        address: l.content.address,
        city: l.content.city,
        state: l.content.state,
        country: l.content.country,
        latitude: l.content.latitude,
        longitude: l.content.longitude,
        restaurantType: l.content.restaurant_type,
        review_url: l.content.review_url,
      },
      path: l.path
    })
  })
  commit('setLocations', locations)
  return
}

async function initDefaultMenu(commit) {
  let defaultMenus = await Storyblok.get('cdn/stories/globals/non-olo-menus')
    .then(res => {
      return res.data.story.content.body
    })
  let menus = defaultMenus.map(m => {
    return {
      menu_id: m.menu_id,
      locs: m.store_codes.split(/\n/)
    }
  })
  commit('setDefaultMenus', menus)
//   let defaultMenu = await axios({
//     method: 'get',
//     url: `${process.env.API_URL}/menus/${process.env.DEFAULT_MENU}`,
//     headers: {
//       'client-id': process.env.API_CLIENT_ID
//     }
//   })
//     .then(res => {
//       return res.data
//     })
//     .catch(err => {
//       return null
//     })
//   if(defaultMenu) {
//     commit('setDefaultMenu', defaultMenu)
//   }
//   return
}

export const getters = { 
  cartCount: state => {
    return state.activeOrder &&  state.activeOrder.products &&  state.activeOrder.products.length  ?  state.activeOrder.products.reduce((ac, next) => ac + next.quantity, 0) : 0   
   },
  productList: (state) => (id) => {
   const prods = state.activeMenu.categories ?  state.activeMenu.categories.map(p=>{
     return p.products
   }) : []
   return  prods ? flatten(prods).find(p=>{
     return p.id === id
   }) : []
  }  

 }