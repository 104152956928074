//
//
//
//
//
//
//
//
//
//
//
//


export default {
  data() {
    return {
      isHidden: true,
    }
  },
  props: [
    'content'
  ]
}
