//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    size: {
      type: String,
      default: 'base'
    },
  },
  computed: {
    dims() {
      const baseSize = { outerClass : 'w-40 h-40', innerClass: 'w-24 h-20' }
      return this.size === 'base' ?  baseSize :  this.size === 'small' ? { outerClass : 'w-16 h-16', innerClass: 'w-16 h-12' } : baseSize
    }
  },
}
