export default (context, inject) => {
  const meta = function(page, schema = false, scripts = []) {
    if(page) {
      let pageMeta = {
        title: page.name,
        meta: [
          // {
          //   hid: 'description',
          //   name: 'description',
          //   content: page.fields.metaDescription
          // },
          {
            hid: 'og:title',
            name: 'og:title',
            content: page.name
          },
          // {
          //   hid: 'og:description',
          //   name: 'og:description',
          //   content: page.fields.metaDescription
          // }
        ],
        script: [],
        link: [
          {
            rel: 'canonical',
            href: `https://www.perkinsrestaurants.com${context.route.path}${context.route.path.match(/\/$/) ? '' : '/'}`
          }
        ]
      }
      scripts.map(s => {
        pageMeta.script.push({ src: s })
      })
      if(schema) {
        let hours = []
        pageMeta.script.push({
          type: 'application/ld+json',
          json: {
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": page.name,
            "image": page.content.image,
            "@id": "https://www.perkinsrestaurants.com/",
            "url": `https://www.perkinsrestaurants.com/${page.path}`,
            "telephone": page.content.phone,
            "address": {
                "@type": "PostalAddress",
                "streetAddress": page.content.address,
                "addressLocality": page.content.city,
                "addressRegion": page.content.state,
                "postalCode": page.content.zipcode,
                "addressCountry": page.content.country
            },
            "openingHoursSpecification": [
              {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": "Sunday",
                "opens": page.content.hours[0].sun_open,
                "closes": page.content.hours[0].sun_close
              },
              {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": "Monday",
                "opens": page.content.hours[0].mon_open,
                "closes": page.content.hours[0].mon_close
              },
              {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": "Tuesday",
                "opens": page.content.hours[0].tue_open,
                "closes": page.content.hours[0].tue_close
              },
              {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": "Wednesday",
                "opens": page.content.hours[0].wed_open,
                "closes": page.content.hours[0].wed_close
              },
              {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": "Thursday",
                "opens": page.content.hours[0].thu_open,
                "closes": page.content.hours[0].thu_close
              },
              {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": "Friday",
                "opens": page.content.hours[0].fri_open,
                "closes": page.content.hours[0].fri_close
              },
              {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": "Saturday",
                "opens": page.content.hours[0].sat_open,
                "closes": page.content.hours[0].sat_close
              },
            ]
          }
        })
      }
      // console.log(pageMeta)
      return pageMeta
    } else {
      return {
        title: 'Not Found'
      }
    }
  }

  inject('metadata', meta)
  context.$metadata = meta
}