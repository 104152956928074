//
//
//
//
//
//
//
//
 
export default {
  
  props: [
    'content'
  ]
}
