//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  computed: {
    errors() {
      let errors = this.$store.state.activeErrors
      let mappedErrors = []
      errors.map(e => {
        switch (e) {
          case 'Token Expired':
          case 'Invalid Access Token':
          case 'Invalid access token':
          case 'Invalid token':
            this.$store.commit('setUserToken', null)
            this.$store.commit('setActiveUser', null)
            this.$store.commit('setActiveOrder', null)
            this.$router.push('/signin')
            mappedErrors.push("For your security you’ve been signed out of your account. You may be prompted to sign in again before completing the next step.")
            break
          // case 'Member mismatch found in system with given email and phone':
          //   mappedErrors.push('This phone number is associated with an existing account. Please try again with a different number or contact us for further assistance')
          //   break
          default:
            mappedErrors.push(e)
        }
      })
      return mappedErrors
    },
    redirect() {
      return this.$store.state.redirect
    }
  },
  data() {
    return {
      showModal: true
    }
  },
  methods: {
    close(error) {
      if (error === "For your security your session has expired. Please login again.") {
        this.showModal = false
        // this.$store.commit('setUserToken', null)
        // this.$store.commit('setActiveUser', null)
        // this.$store.commit('setActiveOrder', null)
        // this.$router.push('/signin')
      } else {
        this.showModal = false
        if(this.redirect && this.redirect !== '/order/product') {
          this.$router.push(this.redirect)
          this.$store.commit('setRedirect', null)
        }
      }
      setTimeout(this.remove, 1000)
    },
    followLink(l) {
      this.$router.push(l)
      this.remove()
    },
    remove() {
      this.$store.commit('setActiveErrors', [])
    }
  }
}
