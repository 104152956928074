import { render, staticRenderFns } from "./Iframe.vue?vue&type=template&id=7729bf3f&scoped=true&"
import script from "./Iframe.vue?vue&type=script&lang=js&"
export * from "./Iframe.vue?vue&type=script&lang=js&"
import style0 from "./Iframe.vue?vue&type=style&index=0&id=7729bf3f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7729bf3f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Iframe: require('/opt/build/repo/components/common/Iframe.vue').default})
