import PhoneField from '~/components/form/PhoneField'

export default function(formulateInstance) {
  formulateInstance.extend({
    components: {
      PhoneField
    },
    library: {
      phone: {
        classification: 'phone',
        component: 'PhoneField'
      }
    }
  })
}
